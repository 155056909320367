body
{
    color: #ccc;
}

img.icon{
    border-radius: 0;
    backdrop-filter: none;
    box-shadow: none;
    filter: invert(1);
    height: 0.8em;
    width: 0.8em;
}

.site-title img{
    height: 2rem;
    width: 2rem;
    vertical-align: bottom;
}

.link-collection li
{
    &.section{
        padding: 0.5rem;
        margin-top: 1rem;
    }
}

.important
{
    border-radius: 1rem;
    border: 1px dotted currentColor;
    padding: 1rem;
    :last-child{
        margin-bottom: 0;
    }

    hr{
        margin: 1rem auto;
    }
}

.page-parent a:before
{
    content: '← ';
}

h1, h2, h3
{
    font-weight: 500;
}

h2
{
    font-size: 1.5rem;
}

ul, ol
{
    list-style-position: outside;
}

li::marker
{
    width: 1rem;
}

header .site-title
{
    font-size: 1rem;
}

.home-content
{
    h1, h2, h3, h4, h5, h6, p
    {
        margin: 0 auto 0.5rem;
    }

    h2
    {
        font-size: 1.25rem;
    }

    .home-description
    {
        margin-bottom: 1rem;
    }

    ul, ol
    {
        padding-left: 1rem;
    }
}