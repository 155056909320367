@font-face {
    font-family: 'Inter';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url("/assets/font/Inter-roman.var.woff2?v=3.19") format("woff2");
}

$font-main: "Inter", san-serif;
$font-title: $font-main;

$color-bg: #111;